import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import styles from '../../theme/styles'
import { ClassValue } from 'classnames/types'
import classNames from 'classnames'

const useStyle = makeStyles((theme) => ({
	...styles(theme),
	fermetureBackground: {
		justifyContent: 'center',
		alignItems: 'center',
		display: 'flex',
		height: 'fit-content',
		minHeight: '100vh',
		width: 'fit-content',
		minWidth: '100vw',
		backgroundImage: 'url(\'/img/visuel_connexion.jpg\')',
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat'
	},
	messageContainer: {
		padding: '25px 42px',
		'& span': {
			fontSize: 18,
			color: theme.palette.blue.main,
			fontFamily: theme.typography.fontFamilyMonserrat
		}
	},
	fermetureContainer: {
		backgroundColor: 'white',
		border: `1px solid ${theme.palette.blue['20']}`,
		boxShadow: '0 2px 0 rgba(175, 175, 175, 0.12)',
		width: '100%',
		maxWidth: 500,
		textAlign: 'center'
	},
	logoContainer: {
		padding: '30px 70px',
	},
	logoBorder: {
		borderBottom: `1px solid ${theme.palette.blue['20']}`
	},
	logoCursor: {
		cursor: 'pointer',
	},
	logoYF: {
		width: '100%',
		maxWidth: 300
	},
	titre: {
		color: theme.palette.blue.main,
		fontFamily: theme.typography.fontFamilyMonserrat,
	},
	textJustify: {
		textAlign: 'justify'
	}
}))
const FermeturePage = () => {
	const classes = useStyle()

	const logoGecinaClass: ClassValue = classNames(
		classes.logoContainer,
		classes.logoBorder
	)

	const logoUpteviaClass: ClassValue = classNames(
		classes.logoYF,
		classes.logoCursor
	)
	return (
		<div className={classes.fermetureBackground}>
			<div className={classes.fermetureContainer}>
				<div className={logoGecinaClass}>
					<img className={classes.logoYF} src="/img/logo_connexion.png"
					     alt="logo yf" />
				</div>
				<div className={classes.messageContainer}>
					<h1 className={classes.titre}>
						Cette plateforme est désormais fermée !
					</h1>
					<span>
						Vous pouvez accéder à la nouvelle plateforme en suivant le lien :
					</span>
					<div className={classes.logoContainer}>
						<img className={logoUpteviaClass} src="/img/logo_uptevia.png" alt="logo Uptevia" onClick={() => {window.location.assign('https://www.gecina.uptevia.com/')}}/>
					</div>
					<div className={classes.textJustify}>
						<span>
							Vous y retrouverez l’ensemble des informations et documents actuellement disponibles dans l’espace actionnaires Gecina, mais également de <b>nouveaux services</b>, tels que la possibilité de réaliser directement des opérations en ligne sur vos titres, ainsi qu’une documentation enrichie.
						</span>
					</div>
					<br/>
					<br/>
					<span>
						Pour toute question ou difficulté de connexion, vous pouvez également contacter la ligne Uptevia dédiée au <b>0 800 000 070</b>.
					</span>
				</div>
			</div>
		</div>
	)
}

export default FermeturePage